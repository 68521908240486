
import { defineComponent } from 'vue'
import {
    getWorkerRecord,
    getWorkerInfo,
    WorkInfo,
    WorkerDto
} from '@/api/workers'
import { exportWorkerPayRecord } from '@/api/export'
import { dateFormat } from '@/utils/util'

export default defineComponent({
    name: 'BookDetail',
    data () {
        return {
            pageSize: 10,
            pageNum: 1,
            total: 0,
            tableData: [] as WorkInfo[],
            month: '',
            peopleInfo: {} as WorkerDto
        }
    },
    created () {
        this.month = dateFormat('YYYY-mm', new Date())
        this.onMonth()
    },
    methods: {
        onMonth () {
            if (!this.month) {
                return
            }
            this.pageNum = 1
            getWorkerInfo({
                userId: this.$route.params.id as string,
                queryMonth: this.month
            }).then(res => {
                this.peopleInfo = res
            })
            this.getWorkerRecord()
        },
        getWorkerRecord () {
            getWorkerRecord({
                userId: this.$route.params.id as string,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryMonth: this.month
            }).then(res => {
                this.tableData = res.list
            })
        },
        handleCurrentChange () {
            this.getWorkerRecord()
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        async onExport () {
            const result = await exportWorkerPayRecord({
                userId: this.$route.params.id as string,
                queryMonth: this.month
            })
            window.location.href =
                process.env.VUE_APP_BASE_URL +
                    '/common/download?delete=true&fileName=' +
                    result

            this.$message.success('导出成功！')
        }
    }
})
